const style = document.createElement('style');
style.innerHTML = `
  .hubble-slider {
    transition: transform 0.3s ease-in-out;
  }
  .hubble-slider-open {
    transform: translateX(0%) !important;
  }

  @media (max-width: 767px) {
    .hubble-popup {
      width: 90% !important;
      height: 80% !important;
      right: 5% !important;
      bottom: 5% !important;
    }
  }
`;
document.head.appendChild(style);

function createIframe(src, width, height) {
  const iframe = document.createElement('iframe');
  iframe.src = src;
  iframe.width = width;
  iframe.height = height;
  iframe.frameBorder = 0;
  iframe.style.overflow = 'auto';
  return iframe;
}

function createPopup(src, width, height) {
  const popup = document.createElement('div');
  popup.className = 'hubble-popup';

  popup.style.position = 'fixed';
  popup.style.right = '20px';
  popup.style.bottom = '20px';
  popup.style.zIndex = 1000;
  popup.style.overflow = 'auto';
  popup.style.display = 'none';
  popup.style.borderRadius = '8px';
  popup.style.marginBottom = '65px';

  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const screenHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  if (screenWidth <= width || screenHeight <= height) {
    width = '90%';
    height = '80%';
  } else {
    width = width || '400px';
    height = height || '400px';
  }

  popup.style.width = width;
  popup.style.height = height;

  const iframe = createIframe(src, '100%', '100%');
  popup.appendChild(iframe);
  return popup;
}

function createChatBubble(iconSrc, onClick) {
  const chatBubble = document.createElement('div');
  chatBubble.style.position = 'fixed';
  chatBubble.style.right = '20px';
  chatBubble.style.bottom = '20px';
  chatBubble.style.width = '60px';
  chatBubble.style.height = '60px';
  chatBubble.style.zIndex = 1001;
  chatBubble.style.backgroundColor = '#0070f3';
  chatBubble.style.borderRadius = '50%';
  chatBubble.style.display = 'flex';
  chatBubble.style.alignItems = 'center';
  chatBubble.style.justifyContent = 'center';
  chatBubble.style.cursor = 'pointer';

  if (typeof iconSrc === 'string' && iconSrc.length > 0) {
    const icon = document.createElement('img');
    console.log('iconSrc: ', iconSrc);
    icon.src = iconSrc;
    icon.style.width = '100%';
    icon.style.height = '100%';
    icon.style.borderRadius = '50%';

    chatBubble.appendChild(icon);
  }

  //   const icon = document.createElement('img');
  //   icon.src = iconSrc;
  //   icon.style.width = '50%';
  //   icon.style.height = 'auto';

  //   chatBubble.appendChild(icon);
  chatBubble.addEventListener('click', onClick);
  return chatBubble;
}

function togglePopupVisibility(popup) {
  popup.style.display = popup.style.display === 'none' ? 'block' : 'none';
}

// function createSliderButton(position, onClick) {
//   const sliderButton = document.createElement('button');
//   sliderButton.style.position = 'fixed';
//   sliderButton.style.zIndex = 1001;
//   sliderButton.style.backgroundColor = '#0070f3';
//   sliderButton.style.color = '#ffffff';
//   sliderButton.style.border = 'none';
//   sliderButton.style.padding = '10px 20px';
//   sliderButton.style.cursor = 'pointer';

//   switch (position) {
//     case 'left':
//       sliderButton.style.top = '50%';
//       sliderButton.style.left = '0';
//       break;

//     case 'top':
//       sliderButton.style.top = '0';
//       sliderButton.style.left = '50%';
//       break;
//     case 'bottom':
//       sliderButton.style.bottom = '0';
//       sliderButton.style.left = '50%';
//       break;
//     case 'right':
//       sliderButton.style.top = '50%';
//       sliderButton.style.right = '0';
//       break;
//     default:
//       sliderButton.style.top = '50%';
//       sliderButton.style.right = '0';
//       break;
//   }

//   sliderButton.textContent = 'Open';
//   sliderButton.addEventListener('click', onClick);
//   return sliderButton;
// }

function createSliderButton(position, toggleSlider) {
  const sliderButton = document.createElement('button');
  sliderButton.classList.add('hubble-slider-button');
  sliderButton.style.position = 'fixed';
  sliderButton.style.zIndex = 1001;
  sliderButton.style[position] = '0';
  sliderButton.style.backgroundColor = '#0070f3';
  sliderButton.style.border = 'none';
  sliderButton.style.color = 'white';
  sliderButton.style.padding = '10px';
  sliderButton.style.cursor = 'pointer';

  if (position === 'left' || position === 'right') {
    sliderButton.style.top = '50%';
    sliderButton.style.transform = 'translateY(-50%)';
  } else {
    sliderButton.style.left = '50%';
    sliderButton.style.transform = 'translateX(-50%)';
  }

  sliderButton.textContent =
    position === 'left'
      ? '➡️'
      : position === 'right'
      ? '⬅️'
      : position === 'top'
      ? '⬇️'
      : '⬆️';

  sliderButton.textContent = 'Open';
  sliderButton.addEventListener('click', toggleSlider);
  return sliderButton;
}

function createSlider(src, width, height, position, toggleSlider) {
  const slider = document.createElement('div');
  slider.classList.add('hubble-slider', `hubble-slider-${position}`);

  const sliderContent = document.createElement('div');
  sliderContent.classList.add('hubble-slider-content');

  const closeButton = document.createElement('button');
  closeButton.classList.add('hubble-slider-close');
  closeButton.textContent = 'X';
  closeButton.onclick = toggleSlider;
  sliderContent.appendChild(closeButton);

  const iframe = createIframe(src, '100%', '100%');
  sliderContent.appendChild(iframe);

  slider.appendChild(sliderContent);
  slider.classList.add('hubble-slider');

  return slider;
}

function toggleSliderVisibility(slider, button) {
  slider.classList.toggle('hubble-slider-open');
  const buttonText = button.textContent;
  button.textContent = buttonText === 'Open' ? 'Close' : 'Open';
}

function initializeEmbed(container) {
  const type = container.getAttribute('data-type');
  const src = container.getAttribute('data-src');
  const width = container.getAttribute('data-width');
  const height = container.getAttribute('data-height');
  const iconSrc = container.getAttribute('data-icon-src');

  switch (type) {
    case 'native':
      container.appendChild(createIframe(src, width, height));
      break;
    case 'chatBubble': {
      const popup = createPopup(src, width, height);
      const chatBubble = createChatBubble(
        iconSrc ||
          'https://dev-creator.hubble.ai/assets/icons/defaultBotIcon.svg',
        () => togglePopupVisibility(popup)
      );
      document.body.appendChild(popup);
      document.body.appendChild(chatBubble);
      // popup.style.display = 'block';

      break;
    }
    case 'slider': {
      const position = container.getAttribute('data-position') || 'right';
      const slider = createSlider(src, width, height, position);
      const sliderButton = createSliderButton(position, () =>
        toggleSliderVisibility(slider, sliderButton)
      );
      document.body.appendChild(slider);
      document.body.appendChild(sliderButton);
      break;
    }
    case 'modal':
      break;
    default:
      throw new Error('Invalid embed type');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const embedContainers = document.querySelectorAll('[data-hubble-app]');
  embedContainers.forEach(initializeEmbed);
});

window.addEventListener('message', (event) => {
  if (event.data.action === 'updateHeight') {
    const iframe = document.querySelector(
      'iframe[src="' + event.data.src + '"]'
    );

    if (iframe) {
      iframe.height = event.data.height + 'px';
      iframe.style.height = event.data.height + 'px';
    }
  }
});
